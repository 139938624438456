@charset "utf-8";
@font-color: #383838;
/**一些公用的css**/

* { margin: 0 auto; padding: 0; border: 0; }

* html body { overflow: visible; }

* html iframe, * html frame { overflow: auto; }

* html frameset { overflow: hidden; }

body { color: #000; font: 14px/22px "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif, serif !important; min-width: 1200px; }

input, select, button { vertical-align: middle; font: 14px/22px "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif, serif; }

textarea, input { word-wrap: break-word; word-break: break-all; padding: 0; font: 12px/22px "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif, serif; }

li { list-style-type: none; }

img { border: 0 none; }

a:link, a:visited { text-decoration: none; }

a:hover { text-decoration: underline; }

hr { margin: 0 auto; }

ol, ul { margin-bottom: 0; }

/* 去掉链接的虚线框 */
a { outline: none; color: black; }

h1 { font-size: 24px; }

h2 { font-size: 20px; }

h3 { font-size: 18px; }

h4 { font-size: 16px; }

h5 { font-size: 14px; }

h6 { font-size: 12px; }

/* 鼠标样式 */
.pointer { cursor: pointer; }

/**一些宽度**/
.w80 { width: 80px; }

.w100 { width: 100px; }

.w120 { width: 120px; }

.w100 { width: 100px; }

.w150 { width: 150px; }

.w200 { width: 200px; }

.w300 { width: 300px; }

.w350 { width: 350px; }

.w400 { width: 400px; }

.form-inline .form-control.w80 { width: 80px; }

.form-inline .form-control.w100 { width: 100px; }

.form-inline .form-control.w120 { width: 120px; }

.form-inline .form-control.w100 { width: 100px; }

.form-inline .form-control.w150 { width: 150px; }

.form-inline .form-control.w200 { width: 200px; }

.form-inline .form-control.w300 { width: 300px; }

.form-inline .form-control.w350 { width: 350px; }

.form-inline .form-control.w400 { width: 400px; }

/*底部样式*/
.footer {
  background-color: #353535;
  height: 180px;
  width: 100%;
  min-width: 1200px;
  hr {
    margin: 0 auto;
    border-top: 1px solid #e0e0e0;
    width: 1200px;
  }
  ul { margin-bottom: 0; }
  .f-top {
    height: 114px;
    line-height: 114px;
    width: 1200px;
    margin: 0 auto;
  }
  .left li {
    float: left;
    margin-right: 63px;
    height: 114px;
    line-height: 114px;
    &:first-child {
      margin-left: 63px;
    }
  }
  .left a {
    color: #ffffff;
    font-size: 18px;
  }
  .left a:hover {
    color: #008aff;
  }

  .select {
    display: inline-block;
    width: 245px;
    height: 32px;
    vertical-align: middle;
    color: #008aff;
    line-height: 30px;
    text-align: left;
    padding-left: 10px;
    border: 1px solid #e0e0e0;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
  }

  .select .glyphicon{
    color: #ffffff;
    padding-right: 10px;
    float: right;
    font-size: 12px;
    line-height: 30px;
  }
  .weixin, .weibo {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  .weixin { margin: 0 17px; }
  .right { text-align: right; }
  .right .pic {
    display: none;
    position: absolute;
    top: -68px;
    z-index: 2;
    left: -30px;
  }
  .right .weibo .pic {
    right: 0;
  }

  .f-bottom {
    text-align: center;
    font-size: 17px;
    height: 65px;
    line-height: 65px;
    color: #ffffff;

    label { font-weight: normal; margin-bottom: 0; }
    span {
      margin-right: 15px;
      font-size: 16px;
    }
    a{color: white;}
    a:hover{
      color: white;
    }
  }

  .more-href{
    display: none;
    position: absolute;
    bottom: 32px;
    //background: url("../images/footer/foot-select.png") repeat;
    background: #353535;
    width: 245px;
    text-align: center;
    left:0;
    li{
      border-bottom: 1px dashed #807d8e;
      line-height: 35px;
      height: 35px;
    }
    li.last{border: none;}
    li a{
      color: white;
      font-size: 14px;
    }
    li:hover{
      font-weight: bold;
    }
  }
}

/*头部样式*/

.header {
  min-width: 1200px;
  background: white;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 4px 0 16px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 40px;
  padding: 0 10px;
  width: 100%;
  z-index: 2;
  .top {
    width: 1200px;
    color: #4d4d4d;
    font-size: 18px;
    line-height: 70px;
    height: 70px;
  }
  .top a { color: #4d4d4d; }
  .top a:hover { color: #1676d2; }
  .top .active a { color: #1676d2; }
  .right { float: right; position: relative; }
  .left { float: left; }
  .right > ul > li {
    float: left;
    margin-left: 54px;
  }
  .right ul > li .line {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #1676d2;
    display: none;
  }
  .right a { line-height: 52px;
    height: 52px; }
  .right a:hover {
    text-decoration: none;
  }
  .sub-menu li {
    float: left;
  }
  .sub-menu { position: absolute;
    height: 45px;
    line-height: 45px;
    background: url("../images/menu-bg.png") repeat-x;
    left: -50%;
    margin-left: 334px;
    display: none;
  }
  .right .sub-menu a {
    color: white;
    font-size: 15px;
    line-height: inherit;
    margin: 0 25px;
  }
  .right .sub-menu a:hover {
    color: #1676d2;
  }
  .sub-menu span {
    background: #666666;
    width: 2px;
    height: 15px;
    display: inline-block;
  }
  .sub-menu.menu1 {
    right: 0;
    margin-right: 208px;
    left: auto;
    margin-left: auto;
  }
  .sub-menu.menu2 {
    margin-left: -134px;
    left: auto;
  }
  .sub-menu.menu3{
    right: 0;
    margin-left: auto;
    left: auto;
    margin-right: auto;
  }
  .weixins{
    float: right;
    margin-left: 20px;
    img{margin-left: 10px;
      cursor: pointer;}

    .weixin{
      position: relative;
    }
    .weixin:hover .pic{
      display: block;
    }
    .pic{
      display: none;
      position: absolute;
      margin: 0;
      left: -27px;
      top: 32px;
    }
  }
}

/*通用内容*/
.container {
  width: 1200px !important;
  padding: 0 20px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  margin-bottom: 70px;
  .title {
    font-size: 20px;
    margin-top: 45px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 30px;
    margin-bottom: 33px;
    label {
      color: #4f8ad4;
      font-weight: normal;
      margin-bottom: 0;
    }
    label:before {
      content: '|';
      font-weight: bold;
      font-size: 25px;
      margin-right: 10px;
    }
    .right { float: right;
      font-size: 16px;
      color: #666666;
      line-height: 27px;
      height: 27px;
    }
    .right span {
      color: #4f8ad4;
    }
    a { color: #666666; }
  }
}

.container-fluid .content {
  color: #4c4c4c;
  letter-spacing: 2px;
  font-size: 16px;
  border-bottom: 1px dashed #d9d9d9;
  padding-bottom: 20px;
  p { margin-bottom: 28px; }
  p:first-child {
    line-height: 2.5;
  }
}

#content {
  border-bottom: none;
  margin-bottom: 50px;
}

.container-fluid {
  background: url(../images/index-line.png) repeat-x;
}

/*banner 效果图*/
.banner {
  position: relative;
  z-index: 1;
  width: 100%;
  img {
    width: 100%;
  }
}

.banner-shadow {
  width: 100%;
  height: 34px;
  background: url("../images/banner-shadow.png") repeat-x;
  position: absolute;
  bottom: -34px;
  //background-color: red;
}

/*置顶*/
#toTop {
  text-align: center;
  cursor: pointer;
  hr{border-top: 2px solid #3a9efe;
    margin: 0;}
}


/*******bigpage 分页******/
.bigpage { text-align: center; margin-top: 35px; line-height: 31px; height: 31px; }

.bigpage .prev, .bigpage .next { background-color: #e9e9e9; padding: 6px 15px; text-decoration: none; color: #333333; }

.bigpage a { border: 1px solid #e9e9e9; padding: 5px 10px; margin: 0 8px; border-radius: 5px; color: #666666; }

.bigpage .pageCurr { background: #337ab7; color: white; border: 1px solid #337ab7; padding: 5px 10px; margin: 0 8px; border-radius: 5px; }

.bigpage a:hover { background: #337ab7; color: white; border: 1px solid #337ab7; }
